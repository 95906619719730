import { DialogService }               from 'aurelia-dialog';
import { EventAggregator }             from 'aurelia-event-aggregator';
import { inject }                      from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { ExternalHighwayListingModal } from 'modules/core/external-tables/highways/listings-modal';
import { Highway }                     from 'modules/management/models/highway';

@inject(EventAggregator, I18N, DialogService)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param eventAggregator
     * @param i18n
     * @param dialogService
     */
    constructor(eventAggregator, i18n, dialogService) {
        this.eventAggregator = eventAggregator;
        this.i18n            = i18n;
        this.dialogService   = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Highway}
     */
    model() {
        let model = new Highway();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.highway_number = {
            type:       'text',
            key:        'highway_number',
            label:      'form.field.highway-number',
            size:       4,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.designation = {
            type:       'text',
            key:        'designation',
            label:      'form.field.designation',
            size:       8,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.code',
            size:       4,
            required:   false,
            attributes: {
                readonly: readonly,
            },
            inputGroup: {
                position: 'right',
                button:   {
                    visible: !readonly,
                    action:  () => this.openHighwaySelectionModal(viewModel),
                    icon:    {
                        class: 'icon-search4',
                    },
                },
            },
        };

        const schema = [
            [this.highway_number, this.designation],
            [this.code],
        ];

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('management.concessions.concessions.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Opens the highway selection modal
     */
    openHighwaySelectionModal(viewModel) {
        this.dialogService.open({
            viewModel: ExternalHighwayListingModal,
            model:     {},
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                viewModel.formSchema.code.instance.model.value = response.output.code;
            }
        });
    }
}
